import React, {useState, useEffect} from "react";
import { Modal, Button } from "antd";
import { getAllProducts } from "../../api/product";
import "./detailmodal.css";


const DetailModal = ({ visible, handleClose, detailData }) => {
  let [checkBoxProducts, setCheckBoxProducts] = useState([]);
  

  useEffect(() => {
    getAllProducts().then((res) => {
      let checkBoxList = res.filter(
        (ele) => ele.input_type === "checkbox" && ele.product_type === "poonya-moments"
      );
      let itemsWithGreenBadge = []
      checkBoxList.map(ele => {
        itemsWithGreenBadge.push(ele.product_name)
      })
      setCheckBoxProducts(itemsWithGreenBadge)
    });
  }, []);
  const itemsWithGreenBadge = [
    "Box",
    "Monitor",
    "Kamera",
    "Lighting",
    "Printer",
    "Sequin",
    "Tiang Sequin",
    "Rak",
    "Kain Bludru",
    "Terminal",
    "Papan Close",
    "Keyboard",
    "Spidol + Gunting",
  ];

  return (
    <Modal
      title={
        <div className="modal-header-custom">
          <div className="staff-info">
            <h4>{detailData.staff}</h4>
          </div>
          <div className="event-info">
            <h5>{detailData.customer}</h5>
            <p>{detailData.date}</p>
          </div>
        </div>
      }
      visible={visible}
      onCancel={handleClose}
      footer={null}
    >
      <h2 style={{ color: "#1890ff" }}>Barang Dibawa</h2>
      <ul className="item-list">
        {detailData.items.map((item, index) => (
          <li key={index}>
            <div className="item-name">{item.name}</div>
            <div
              className={`item-status ${
                checkBoxProducts.includes(item.name) ? "green" : ""
              }`}
            >
              {item.status}
            </div>
          </li>
        ))}
      </ul>
      <div className="modal-footer-custom">
        <Button key="close" onClick={handleClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default DetailModal;
