import React, { Component } from "react";

import StaffListEvent from "../pages/StaffListEvent";

export default class StaffListEventView extends Component {
  render() {
    return (
      <div>
        {/* <Header {...this.props}></Header> */}
        <StaffListEvent data={StaffListEvent}></StaffListEvent>
      </div>
    );
  }
}
