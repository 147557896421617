import React, { Component } from 'react'


import Login from '../pages/LoginPage'

export default class LoginView extends Component {
  render() {
    return (
      <div>
        
     {/* <Header {...this.props}></Header> */}
     <Login data={Login}></Login>
      </div>
    )
  }
}
