import React from "react";
import StockView from "./route/stock-view";
import EventView from "./route/event-view";
import StaffStartView from "./route/staffstart-view";
import StaffEventView from "./route/staffevent-view";
import StaffBerangkatView from "./route/staffberangkat-view";
import StaffPulangView from "./route/staffpulang-view";
import StaffKehilanganView from "./route/staffkehilangan-view";
import StaffFinishView from "./route/stafffinish-view";
import StaffListEventView from "./route/stafflistevent-view";


import InventoryView from "./route/inventory-view";
// import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import LoginView from "./route/login-view";
// import { ProtectedRoute } from 'protected';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

const App = () => {
  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<LoginView />}></Route>
          <Route exact path="/stock" element={<StockView />}></Route>
          <Route path="/event" element={<EventView />}></Route>
          <Route path="/inventory" element={<InventoryView />}></Route>
          <Route path="/staff-start" element={<StaffStartView />}></Route>
          <Route path="/staff-event" element={<StaffEventView />}></Route>
          <Route path="/staff-pulang" element={<StaffPulangView />}></Route>
          <Route path="/staff-finish" element={<StaffFinishView />}></Route>
          <Route
            path="/staff-listevent"
            element={<StaffListEventView />}
          ></Route>
          <Route
            path="/staff-kehilangan"
            element={<StaffKehilanganView />}
          ></Route>
          <Route
            path="/staff-berangkat"
            element={<StaffBerangkatView />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </PersistGate>
        </Provider>

    </div>
  );
};

export default App;
