import React from "react";
import { useLocation } from "react-router-dom";
import "./sidebar.css";
import logo from "../../assets/images/logo.png";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.clear()
    dispatch({ type: 'LOGOUT' });
    navigate('/');
  };

  const navigateUrl = (url) => {
    if(url == 'stock') navigate('/stock');
    else if(url == 'event') navigate('/event');
    else if(url == 'inventory') navigate('/inventory');

   };

  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <img src={logo} alt="Studio Poonya Logo" />
        <h1>Studio Poonya</h1>
      </div>
      <ul className="sidebar-menu">
        <li>
        <a style={{cursor: "pointer"}}
              onClick={() => navigateUrl('stock')}
              className={`${location.pathname === "/stock" ? "active" : "pointer"}   `}
            >
              Stock
            </a>
        </li>
        <li>
        <a style={{cursor: "pointer"}}
              onClick={() => navigateUrl('event')}
              className={`${location.pathname === "/event" ? "active" : "pointer"}   `}
            >
              Event
            </a>
        </li>
        <li>
        <a style={{cursor: "pointer"}}
              onClick={() => navigateUrl('inventory')}
              className={`${location.pathname === "/inventory" ? "active" : "pointer"}   `}
            >
              Inventory
            </a>
        </li>
        <li>
          <a style={{cursor: "pointer"}}
            onClick={() => logout()}
            className={`${location.pathname === "/" ? "active" : "pointer"}   `}
          >
            Logout
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
