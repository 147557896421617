import axios from 'axios';
import { BACKEND_API_URL } from '../index';
import { Product } from '../../model/product';
import React, { useState } from "react";

export const getAllProducts = async () => {
  try {
   
    // Await the axios call and get the response
    const res = await axios.get(`${BACKEND_API_URL}/products`);
    // Assuming res.data contains the response we need
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const createProduct = async (data) => {
  let user = JSON.parse(localStorage.getItem('userInfo'));
  try {
    let body = {
      "product_name" : data.name,
      "product_type": data.productType,
      "quantity": data.quantity,
      "input_type": data.inputType,
      "price" : data.price,
      "limit_stock": data.inputType == 'checkbox' || data.inputType == 'radio' ? 1 : 500,
      "purchase_date": data.purchaseDate,
      "createdBy": user.username,
      "createdBy": user.username
    }
    // Await the axios call and get the response
    const res = await axios.post(`${BACKEND_API_URL}/product`, body);
    // Assuming res.data contains the response we need
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
