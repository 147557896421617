import React from "react";
import { formatDate } from "../../utils/datetime";
import "./stockcard.css";

const stockcard = ({ title, quantity, lastUpdate, logo, large }) => {
  return (
    <div className={`card ${large ? "large" : ""}`}>
      <div className="image-container">
        <img src={logo} alt="Item" className="card-image" />
      </div>
      <div className="card-content">
        <h3>{title}</h3>
        <p>{quantity} Pcs</p>
        <small>Last Update: {formatDate(lastUpdate)}</small>
      </div>
    </div>
  );
};

export default stockcard;
