
export const setEventId = (event_id) => ({
    type: "SET_EVENT_ID",
    payload: event_id,
  });
  
export const setLoginInfo = (data) => ({
    type: "SET_LOGIN",
    payload: {
      username: data.username,
      role: data.role
    }
})
  