import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import {useSelector, useDispatch} from 'react-redux';


const StaffStart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginReducer = useSelector(state => state.loginReducer);
  
  useEffect(() => {
  }, []);

  const handleButtonClick = () => {
    navigate('/staff-event');
    // navigate(0)
  };

  const handleButtonClickEvent = () => {
    navigate('/staff-listevent');
    // navigate(0)
  };

  const logout = () => {
    localStorage.clear()
    dispatch({ type: 'LOGOUT' });
    navigate('/');
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Full viewport height
      textAlign: 'center'
    }}>
      <h1 className="title">
        Haloo Staff Poonya{" "}
        <span role="img" aria-label="wave">
          👋
        </span>
      </h1>
      <button className="event-button" style={{marginBottom:'20px', backgroundColor:''}} onClick={handleButtonClick}>Buat Event</button> 
      <button className="event-button" style={{backgroundColor:'blue', marginBottom:'60px'}} onClick={handleButtonClickEvent}>List Event</button>
      <button className="event-button" style={{backgroundColor:'red'}} onClick={logout}>Logout</button> 
    </div>
  );
};

export default StaffStart;
