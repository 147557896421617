import React from "react";
import { Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { getAllEvents, getEventStaffByEvent } from "../../api/event";
import { getAllStaff} from "../../api/staff";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { setEventId, setLoginInfo } from '../../redux/actions/actionEvents';
import { formatDate } from "../../utils/datetime";


import "./index.css";

const StaffListEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eventReducer = useSelector((state) => state.eventReducer);
  
  const [eventList, setEventList] = useState([])
  const [eventStaffList, setEventStaffList] = useState([])
  const [staffList, setStaffList] = useState([])
  
  useEffect(() => {
    getAllStaff().then(data => {
    getAllEvents().then(res => {
      let filteredEvents = res.events.filter((event) => {
        return event.status != "event-finished" && event.status != "event-cancelled" ;
    });
      console.log('eventList',filteredEvents)
      const result = filteredEvents.map(v => 
        ({ 
          ...v,
          ...res.eventStaff.find(sp => sp.event_id === v.event_id)}));
      const resultStaff = result.map(v => 
        ({ 
          ...v,
          ...data.find(sp => sp.staff_id === v.staff_id) }));
      setEventList(resultStaff)
      setEventStaffList(res.eventStaff)
    })
    .catch(err => console.log(err))
  })


  }, []);

  const updateEventId = (data) => {
    dispatch({
        type: 'SET_EVENT_ID',
        value: data,
      });
  }

  const handleButtonClick = (event) => {
    updateEventId(event.event_id);
    
    if(event.status == 'register-event'){
      navigate('/staff-berangkat')
    }
    else if(event.status == 'form-berangkat') {
      navigate('/staff-pulang')
    }
    else if(event.status == 'form-pulang') {
      navigate('/staff-kehilangan')
    } 
    else  if(event.status == 'event-finished') 
    {
      navigate('/staff-finish')
    }
    // else  if(event.status == 'event-canceled') {
    //   //event-canceled  
    //   navigate('/staffberangkat')
    // }
  };

  const sortedEventList = [...eventList].sort((a, b) => {
    if (a.statusType === "register-event" && b.statusType !== "register-event") {
      return -1;
    }
    if (a.statusType !== "register-event" && b.statusType === "register-event") {
      return 1;
    }
    return 0;
  });

  const back = () => {
    navigate('/staff-start');
  };


  return (
    <div className="container">
      <div className="header" style={{background:"#1F1E30"}}>
        
        <h1 style={{marginTop:"20px", marginLeft:"20px",}}>
          List Event{" "}
          <span role="img" aria-label="wave">
            👋
          </span>
        </h1>
      </div>
      <div className="content">
        {eventList.map((event, index) => (
        <div key={index} className="event-item"   onClick={() => handleButtonClick(event)}
        style={{ cursor: "pointer" }}>
          <div className="event-info">
            <h2>{event.customer_id}</h2>
            <p>
            PIC: {event.fullname}
            </p>
            <p>
            Event Date: {formatDate(event.event_date)}
            </p>
          </div>
          <Button
            className={event.status == 'register-event' ? "event-item-register" : "event-item-berangkat"}
            size="medium"
            onClick={() => handleButtonClick(event)}
          >
            {event.status}
          </Button>
        </div>
      ))}
        {/* {eventId && (
          <div className="selected-event">
            <h3>Selected Event ID: {eventId}</h3>
          </div>
        )} */}
      </div>
      <Button onClick={back} type="danger" htmlType="submit" style={{ width: "100%", backgroundColor:'red'  }}>
              Back
            </Button>
    </div>
  );
};

const mapStateToProps = state => ({
  value: state.eventReducer
});

const mapDispatchToProps = {
  setEventId
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffListEvent);
