import React, { Component } from 'react'


import Event from '../pages/Event'

export default class EventView extends Component {
  render() {
    return (
      <div>
        
     {/* <Header {...this.props}></Header> */}
     <Event data={Event}></Event>
      </div>
    )
  }
}
