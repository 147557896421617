import { combineReducers } from "redux";

const initialStateEvent = {
    event_id: 0,
  };

  export const eventReducer = (state = initialStateEvent, action) => {
    switch (action.type) {
      case "SET_EVENT_ID":
        return {
          ...state,
          event_id: action.value,
        };
      default:
        return state;
    }
  };


  const initialStateLogin = {
    username: '',
    role: ''
};

export const loginReducer = (state = initialStateLogin, action) => {

    switch (action.type) {
      case "SET_LOGIN":
        let res = {
          ...state,
          username  : action.value.username,
          role: action.value.role
        }
        return {
          ...state,
          username  : action.value.username,
          role: action.value.role
        };
      default:
        return state;
    }

   
};