import axios from 'axios';
import { BACKEND_API_URL } from '../index';
import { Staff } from '../../model/staff';

export const loginDashboard = async (username, password) => {
  try {
    const body = {
      username: username,
      password: password
    };

    // Await the axios call and get the response
    const res = await axios.post(`${BACKEND_API_URL}/login`, body);
    
    // Assuming res.data contains the response we need
    const user = new Staff(
      res.data.response.fullname, 
      res.data.response.role, 
      res.data.response.phone_number, 
      res.data.response.username
    );

    // Check and set the localStorage items
    if (!localStorage.getItem('userInfo')) {
      localStorage.setItem("userInfo", JSON.stringify(user));
      localStorage.setItem("access_token", res.data.token);
    } else {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("access_token");
      localStorage.setItem("userInfo", JSON.stringify(user));
      localStorage.setItem("access_token", res.data.token);
    }

    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAllStaff = async () => {
  try {
   
    // Await the axios call and get the response
    const res = await axios.get(`${BACKEND_API_URL}/staff`);
    // Assuming res.data contains the response we need
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getStaffById = async (staff_id) => {
  try {
   
    // Await the axios call and get the response
    const res = await axios.get(`${BACKEND_API_URL}/staff/id/${staff_id}`);
    // Assuming res.data contains the response we need
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};