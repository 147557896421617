import React, { Component } from "react";

import StaffStart from "../pages/StaffStart"

export default class StaffStartView extends Component {
  render() {
    return (
      <div>
        {/* <Header {...this.props}></Header> */}
        <StaffStart data={StaffStart}></StaffStart>
      </div>
    );
  }
}
