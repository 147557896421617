import React, { Component } from 'react'


import Inventory from '../pages/Inventory'

export default class InventoryView extends Component {
  render() {
    return (
      <div>
        
     {/* <Header {...this.props}></Header> */}
     <Inventory data={Inventory}></Inventory>
      </div>
    )
  }
}
