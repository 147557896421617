import React from "react";
import { Button, Input, Select, Steps, Form } from "antd";
import "./index.css";
import {useState, useEffect} from 'react';
import { getEventLostOption, updateEventKehilangan, updateEventFinished, getEventById, getEventStaffByEvent  } from "../../api/event"; 
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/datetime";
import { getAllStaff } from "../../api/staff";  

const { Step } = Steps;
const { Option } = Select;
const { TextArea } = Input;

const StaffKehilangan = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [productList, setProductList] = useState([]);
  let [eventDetail, setEventDetail] = useState();
  let [eventStaffDetail, setEventStaffDetail] = useState([]);

  const dispatch = useDispatch();

  const eventReducer = useSelector(state => state.eventReducer);


  useEffect(() => {
    getEventDetailAndStaff();
    let event_id = eventReducer.event_id
    getEventLostOption(event_id).then(res => {
      setProductList(res)
    })
    .catch(err => console.log(err))
    
  }, []);

  const getEventDetailAndStaff = async () => {
    await getAllStaff().then(res => {
      getEventStaffByEvent(eventReducer.event_id).then(data => {
        const resultStaff = data.eventStaff.map(v => 
          ({ 
            ...v,
            ...res.find(sp => sp.staff_id === v.staff_id) 
          })
        );
        setEventStaffDetail(resultStaff);
      });
    });

    await getEventById(eventReducer.event_id).then(response => {
      setEventDetail(response);
    });
  }

  const handleSubmitAnother = () => {
    let payload = {
      "event_id": eventReducer.event_id,
      "product_id": form.getFieldValue("lost_product"),
      "lost_amount": form.getFieldValue("lost_amount"),
      "lost_reason": form.getFieldValue("lost_reason")
    }
    updateEventKehilangan(payload).then(res => {
      alert("Submit kehilangan success") //
      form.resetFields()
    })
  } 

  const handleFinished = () => {
    let event_id = eventReducer.event_id
    updateEventFinished(event_id).then(res => {
      navigate('/staff-finish')
    })
    .catch(err => console.log(err))
  }


  return (
    <div className="container">
      <div className="header">
      <div className="header-info">
              <h2>{eventStaffDetail.length != 0 ? eventStaffDetail[0].fullname : ''}</h2>
              <p>{eventDetail != undefined ? eventDetail.customer_id : ''} - {eventDetail != undefined ? formatDate(eventDetail.event_date) : ''}</p>
        </div>
        <Button type="primary" size="large">
          Form Kehilangan
        </Button>
      </div>
      <Steps current={3} className="steps-bar">
        <Step title="Input Event" />
        <Step title="Form Berangkat" />
        <Step title="Form Pulang" />
        <Step title="Form Kehilangan" />
      </Steps>
      <div className="form-container">
        <h2 style={{ textAlign: "left" }}>Form Kehilangan</h2>
      <Form
        form={form}
      >
        <label style={{ textAlign: "left", paddingBottom: "10px" }}>
          Pilih Items
        </label>
      <Form.Item name="lost_product">
        <Select style={{ width: "100%" }}>
          {productList.map((item) => (
            <Option key={item.product_id} value={item.product_id}>
              {item.product_name}
            </Option>
          ))}
        </Select>
        </Form.Item>

        <label
          style={{
            textAlign: "left",
            paddingBottom: "10px",
            paddingTop: "10px",
          }}
        >
          Jumlah
        </label>
        <Form.Item name="lost_amount">
          <Input type="number" />
        </Form.Item>

        <label
          style={{
            textAlign: "left",
            paddingBottom: "10px",
            paddingTop: "10px",
          }}
        >
          Alasan
        </label>
        <Form.Item name="lost_reason">
          <TextArea rows={4} />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          onClick={handleSubmitAnother}
          style={{ width: "45%", marginTop: "10px" }}
        >
          Submit Item
        </Button>
        <> </>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "45%", marginTop: "10px" }}
          onClick={handleFinished}
        >
          Finish
        </Button>
      </Form>

      </div>
    </div>
  );
};

export default StaffKehilangan;
