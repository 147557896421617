import React, { Component } from "react";

import StaffBerangkat from "../pages/StaffBerangkat";

export default class StaffBerangkatView extends Component {
  render() {
    return (
      <div>
        {/* <Header {...this.props}></Header> */}
        <StaffBerangkat data={StaffBerangkat}></StaffBerangkat>
      </div>
    );
  }
}
