import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css'; // Import the CSS file]
import {loginDashboard} from '../../api/staff';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { setEventId, setLoginInfo } from '../../redux/actions/actionEvents';
import { Alert } from 'antd';


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  
  const loginReducer = useSelector(state => state.loginReducer);
  const dispatch = useDispatch();

  const updateLoginInfo = (data) => {
    dispatch({
        type: 'SET_LOGIN',
        value: data,
      });
  }


  const handleLogin = async () => {
    try {
      
      await loginDashboard(username, password).then(
        (res) => {
          
          // Set value for store in redux
          let login_info = {
            username: username,
            role: res.response.role
          }
          
          updateLoginInfo(login_info)
          
          if(res.response.role == "owner"){
            navigate("/stock");
            // navigate(0)
          }
          else
          navigate("/staff-start");
        // navigate(0)
        
      }
    )
    .catch(err => console.log(err))
    
    } catch (error) {
      // setError("Login failed. Please check your credentials.");
      console.log("error")
      // Alert("testtt")
    }
    
  };


  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        <input
          type="text"
          className="input"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          className="input"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="button" onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  value: state.loginReducer
});

const mapDispatchToProps = {
  setLoginInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
