export const formatDate = (dateString) => {
    // Assuming dateString is in ISO format or can be parsed by Date()
    const date = new Date(dateString);
    
    // Get day, month, and year from date object
    const day = date.getDate().toString().padStart(2, '0'); // Ensure 2 digits with leading zero
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
    const year = date.getFullYear();
  
    // Format to dd-mm-yyyy
    return `${day}-${month}-${year}`;
  };