import React, { Component } from 'react'

import Stock from '../pages/Stock'

export default class StockView extends Component {
  render() {
    return (
      <div>
        
     {/* <Header {...this.props}></Header> */}
     <Stock data={Stock.hero}></Stock>
      </div>
    )
  }
}
