import React from "react";
import { Form, Button, Checkbox, Steps, InputNumber, Select } from "antd";
import "./index.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateEventBerangkat, getEventById } from "../../api/event";
import { getAllProducts } from "../../api/product";
import { getAllStaff } from "../../api/staff";
import { useSelector, useDispatch } from "react-redux";
import { getEventStaffByEvent } from "../../api/event";
import { connect } from 'react-redux';
import { setEventId, setLoginInfo } from '../../redux/actions/actionEvents';
import { formatDate } from "../../utils/datetime";
const { Option } = Select;

const { Step } = Steps;
const { Group: CheckboxGroup } = Checkbox;

const StaffBerangkat = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [productList, setProductList] = useState([]);
  let [checkBoxProducts, setCheckBoxProducts] = useState([]);
  let [numberProducts, setNumberProducts] = useState([]);
  let [eventDetail, setEventDetail] = useState();
  let [eventStaffDetail, setEventStaffDetail] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState('');
  const eventReducer = useSelector(state => state.eventReducer);

  const formatter = value => value.replace(/\D/g, '');
  const parser = value => value.replace(/\D/g, '');

  const handlePrinterChange = (value) => {
    setSelectedPrinter(value); // Update state on change
  };

  useEffect(() => {
    getEventDetailAndStaff();
    getAllProducts().then((res) => {
      let checkBoxList = res.filter(
        (ele) => ele.input_type === "checkbox" && ele.product_type === "poonya-moments"
      );
      let inputNumberList = res.filter(
        (ele) => ele.input_type === "number" && ele.product_type === "poonya-moments"
      );
      setProductList(res);
      setCheckBoxProducts(checkBoxList);
      setNumberProducts(inputNumberList);
    });
  }, []);

  const getEventDetailAndStaff = async () => {
    await getAllStaff().then(res => {
      getEventStaffByEvent(eventReducer.event_id).then(data => {
        const resultStaff = data.eventStaff.map(v => 
          ({ 
            ...v,
            ...res.find(sp => sp.staff_id === v.staff_id) 
          })
        );
        setEventStaffDetail(resultStaff);
      });
    });

    await getEventById(eventReducer.event_id).then(response => {
      setEventDetail(response);
    });
  }

  const labelPlaceholder = (data) => {
    if(data == "Cover Frame 2R") return "Jumlah dalam pcs (only number)";
    else if(data == "Paper") return "Jumlah dalam pcs (only number)"
    else if(data == "Cover Frame 4R") return "Jumlah dalam pcs (only number) "
    else if(data == "Magnet") return "Jumlah dalam pcs (only number) "
    else if(data == "Property Bando") return "Jumlah dalam pcs (only number) "
    else if(data == "Property Kacamata") return "Jumlah dalam pcs (only number) "
    else if(data == "Kartu Nama") return "Jumlah dalam Box (only number)"
    else if(data == "Lakban") return "Jumlah dalam pcs (only number) "
    else return "Jumlah dalam pcs (only number) ";
  };

  const handleSubmit = (e) => {
    let finalProductPayload = [];

    // Loop for transform input product (number) to array of object -- ignore product with 0 amount
    numberProducts.forEach((item) => {
      if (e[item.product_id] > 0) {
        finalProductPayload.push({
          product_id: item.product_id,
          amount_before: e[item.product_id],
          amount_after: 0,
        });
      }
    });

    // Loop for transform input product (checkbox) to array of object -- fixed amount 1
    e.checkbox_product.forEach((product_id) => {
      finalProductPayload.push({
        product_id,
        amount_before: 1,
        amount_after: 0,
      });
    });

    let payload = {
      event_id: eventReducer.event_id,
      product: finalProductPayload,
    };

    updateEventBerangkat(payload).then((res) => {
      navigate("/staff-listevent");
    });
  };

  return (
    <div className="container">
      <div className="header">
        <div className="header-info">
              <h2>{eventStaffDetail.length != 0 ? eventStaffDetail[0].fullname : ''}</h2>
              <p>{eventDetail != undefined ? eventDetail.customer_id : ''} - {eventDetail != undefined ? formatDate(eventDetail.event_date) : ''}</p>
        </div>

        <Button type="primary" size="large" style={{width:'150px'}}>
          Form Berangkat
        </Button>
      </div>
      <Steps current={1} className="steps-bar">
        <Step key="stepEventInput" title="Input Event" />
        <Step key="stepEventBerangkat" title="Form Berangkat" />
        <Step key="stepEventPulang" title="Form Pulang" />
        <Step key="stepEventKehilangan" title="Form Kehilangan" />
      </Steps>
      <div className="form-container">
        <h2 style={{ textAlign: "left" }}>Form Berangkat</h2>
        <Form name="eventForm" layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="checkbox_product" rules={[{ required: true }]}>
            <CheckboxGroup style={{ width: "100%" }}>
              <div className="checkbox-grid">
                {checkBoxProducts.map((option) => (
                  <Checkbox key={option.product_id} value={option.product_id}>
                    {option.product_name}
                  </Checkbox>
                ))}
              </div>
            </CheckboxGroup>
          </Form.Item>
          {numberProducts.map((item) => (
            <div key={item.product_id}>
              <Form.Item
                label={item.product_name}
                name={item.product_id}
                rules={[{ required: true }]}
              >
                <InputNumber 
                value={0} 
                style={{ width: "100%" }} controls={false} 
                formatter={formatter}
                parser={parser}
                placeholder={labelPlaceholder(item.product_name)}/>
              </Form.Item>
            </div>
          ))}
        {/* <Form.Item
        label="Printer"
        name="printer"
        rules={[{ required: true, message: 'Please select a printer!' }]}
      >
        <Select
          placeholder="Select a printer"
          style={{ width: '100%' }}
          value={selectedPrinter}
          onChange={handlePrinterChange}
        >
          <Option value="kodak305">Kodak 305</Option>
          <Option value="dnp">DNP</Option>
        </Select>
      </Form.Item> */}
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%", marginTop: "10px" }}
          >
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  value: state.eventReducer
});

const mapDispatchToProps = {
  setLoginInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffBerangkat);
