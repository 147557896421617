import React, { useState, useEffect } from "react";
import "./index.css";

import Sidebar from "../../components/Sidebar/sidebar";
import DetailModal from "../../components/DetailModal/detailmodal";
import { getAllEvents, getEventProductByEvent, getEventStaffByEvent, getEventById } from "../../api/event";
import { getAllStaff } from "../../api/staff";
import { formatDate } from "../../utils/datetime";
import { eventReducer } from "../../redux/reducers/eventReducers";
import { connect } from "react-redux";
import { setEventId } from "../../redux/actions/actionEvents";

const TabbedInterface = () => {
  const [activeTab, setActiveTab] = useState("form-berangkat");
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [eventList, setEventList] = useState([])
  let [checkBoxProducts, setCheckBoxProducts] = useState([]);
  let [numberProducts, setNumberProducts] = useState([]);
  let [eventDetail, setEventDetail] = useState();
  let [eventStaffDetail, setEventStaffDetail] = useState([]);

  const detailData = {
    staff: "Yodi & Oya",
    customer: "Eka Nanda",
    date: "25 Mei 2024",
    items: [
      { name: "Box", status: "Sudah Dibawa" },
      { name: "Monitor", status: "Sudah Dibawa" },
      { name: "Kamera", status: "Sudah Dibawa" },
      { name: "Lighting", status: "Sudah Dibawa" },
      { name: "Printer", status: "Sudah Dibawa" },
      { name: "Sequin", status: "Sudah Dibawa" },
      { name: "Tiang Sequin", status: "Sudah Dibawa" },
      { name: "Rak", status: "Sudah Dibawa" },
      { name: "Kain Bludru", status: "Sudah Dibawa" },
      { name: "Terminal", status: "Sudah Dibawa" },
      { name: "Papan Close", status: "Sudah Dibawa" },
      { name: "Keyboard", status: "Sudah Dibawa" },
      { name: "Kertas Cadangan", status: "1 Buah" },
      { name: "Cover Frame 2R", status: "200 pcs" },
      { name: "Cover Frame 4R", status: "150 pcs" },
      { name: "Lakban", status: "1 Buah" },
      { name: "Kartu Nama", status: "1 Box" },
      { name: "Spidol + Gunting", status: "Sudah Dibawa" },
    ],
  };


  useEffect(() => {
    // let event_id = 8
    getAllEvents().then(res => {
      setEventList(res.events)
    })
    .catch(err => console.log(err))
  
  }, []);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const getEventDetailAndStaff = async (event_id) => {
    await getAllStaff().then(res => {
      getEventStaffByEvent(event_id).then(data => {
        const resultStaff = data.eventStaff.map(v => 
          ({ 
            ...v,
            ...res.find(sp => sp.staff_id === v.staff_id) 
          })
        );
        setEventStaffDetail(resultStaff);
      });
    });

    await getEventById(event_id).then(response => {
      setEventDetail(response);
    });
  }

  const getDetailData = async (event_id) => {
    let resultArr
    let itemsArr = [];
    await getEventDetailAndStaff(event_id);
    await getEventProductByEvent(event_id).then(res => {
      let checkBoxList = res.product.filter(
        (ele) => ele.input_type === "checkbox" && ele.product_type === "poonya-moments"
      );
      let inputNumberList = res.product.filter(
        (ele) => ele.input_type === "number" && ele.product_type === "poonya-moments"
      );

      // setCheckBoxProducts(checkBoxList);
      // setNumberProducts(inputNumberList);

      checkBoxList.map(ele => {
        itemsArr.push({
          name: ele.product_name,
          status: "Sudah Dibawa"
        })
      })
      inputNumberList.map(ele => {
        itemsArr.push({
          name: ele.product_name,
          status: `${ele.quantity} pcs`
        })
      })
       resultArr = {
        staff: eventStaffDetail.length != 0 ? eventStaffDetail[0].fullname : '',
        customer: eventDetail != undefined ? eventDetail.customer_id : '',
        date: eventDetail != undefined ? formatDate(eventDetail.event_date) : '',
        items: itemsArr
      }
    })
    return resultArr
  }

  const handleDetailClick = (event_id) => {
    getDetailData(event_id).then(res => {
      console.log(res)
      setSelectedDetail(res);
      setModalVisible(true);
    })
    
  };

 return (
    <div className="app-container">
      <Sidebar />
      <div className="content">
        {/* <Header /> */}
        <div className="header-row">
          <div className="tabs">
            <button
              className={`tab ${activeTab === "form-berangkat" ? "active" : ""}`}
              onClick={() => handleTabClick("form-berangkat")}
            >
              Form Berangkat
            </button>
            <button
              className={`tab ${activeTab === "form-pulang" ? "active" : ""}`}
              onClick={() => handleTabClick("form-pulang")}
            >
              Form Pulang
            </button>
            <button
              className={`tab ${
                activeTab === "event-finished" ? "active" : ""
              }`}
              onClick={() => handleTabClick("event-finished")}
            >
              Event Finished
            </button>
          </div>
        </div>
        <div className="content-inner">
          {activeTab === "form-berangkat" && (
            <table className="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Nama Staff</th>
                  <th>Nama Customer</th>
                  <th>Tanggal Acara</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {eventList.filter(e => e.status == "form-berangkat").map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.event_name}</td>
                    <td>{item.customer_id}</td>
                    <td>{formatDate(item.event_date)}</td>
                    <td>
                      <button
                        className="detail-button"
                        onClick={() => handleDetailClick(item.event_id)}
                      >
                        Detail
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {activeTab === "form-pulang" && (
            <table className="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Nama Staff</th>
                  <th>Nama Customer</th>
                  <th>Tanggal Acara</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {eventList.filter(e => e.status == "form-pulang").map((item, index) => (
                  <tr key={item.id}>
                   <td>{index + 1}</td>
                    <td>{item.event_name}</td>
                    <td>{item.customer_id}</td>
                    <td>{formatDate(item.event_date)}</td>
                    <td>
                      <button
                        className="detail-button"
                        onClick={() => handleDetailClick(item.event_id)}
                      >
                        Detail
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {activeTab === "event-finished" && (
            <table className="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Nama Staff</th>
                  <th>Nama Customer</th>
                  <th>Tanggal Acara</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {eventList.filter(e => e.status == "event-finished").map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.event_name}</td>
                    <td>{item.customer_id}</td>
                    <td>{formatDate(item.event_date)}</td>
                    <td>
                      <button
                        className="detail-button"
                        onClick={() => handleDetailClick(item.event_id)}
                      >
                        Detail
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <DetailModal
        visible={modalVisible}
        handleClose={() => setModalVisible(false)}
        detailData={selectedDetail || detailData}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  value: state.eventReducer
});

const mapDispatchToProps = {
  setEventId
};

export default connect(mapStateToProps, mapDispatchToProps)(TabbedInterface);
