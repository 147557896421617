import React, { useState, useEffect } from "react";
import { Button, Checkbox, Steps, Form, InputNumber } from "antd";
import "./index.css";
import { getEventProductByEvent, updateEventPulang } from "../../api/event";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getEventStaffByEvent, getEventById } from "../../api/event";
import { formatDate } from "../../utils/datetime";
import { getAllStaff } from "../../api/staff";
import { EMAIL_CONFIG } from "../../const/email";
import emailjs from '@emailjs/browser';



const { Step } = Steps;
const { Group: CheckboxGroup } = Checkbox;

const StaffPulang = () => {
  const navigate = useNavigate();

  const eventReducer = useSelector(state => state.eventReducer);
  
  // let [productList, setProductList] = useState([]);
  let [berangkatProductList, setBerangkatProductList] = useState([]);
  let [checkBoxProducts, setCheckBoxProducts] = useState([]);
  let [numberProducts, setNumberProducts] = useState([]);
  let [checkedProducts, setCheckedProducts] = useState({});
  let [eventDetail, setEventDetail] = useState();
  let [eventStaffDetail, setEventStaffDetail] = useState([]);

  useEffect(() => {
    let event_id = eventReducer.event_id;
    getEventDetailAndStaff();
    getEventProductByEvent(event_id)
    .then((res) => {
      const result = res.product.map(v => 
        ({ 
           ...res.eventProduct.find(sp => sp.product_id === v.product_id && v.input_type === "checkbox" && v.product_type === "poonya-moments") }));
      let checkBoxList = res.product.filter(
        (ele) => ele.input_type === "checkbox" && ele.product_type === "poonya-moments"
      );
      let inputNumberList = res.product.filter(
        (ele) => ele.input_type === "number" && ele.product_type === "poonya-moments"
      );
      // setProductList(res);
      setCheckBoxProducts(checkBoxList);
      setNumberProducts(inputNumberList);
      setBerangkatProductList(res.eventProduct)
    });

    // getEventProductByEvent(event_id).then((res) => {
    //   setBerangkatProductList(res);
    // });
  }, []);

  useEffect(() => {
    const initialCheckedState = {};
    checkBoxProducts.forEach((product) => {
      initialCheckedState[product.product_id] = checkProductBerangkat(product);
    });
    setCheckedProducts(initialCheckedState);
  }, [checkBoxProducts, berangkatProductList]);

  const checkProductBerangkat = (data) => {
    let res = berangkatProductList.filter(item => item.product_id === data.product_id);
    return res.length === 1;
  };

  const onChange = (product_id) => (e) => {
    setCheckedProducts({
      ...checkedProducts,
      [product_id]: e.target.checked,
    });
  };

  const defaultValue = (product) => {
    let res = berangkatProductList.filter(item => item.product_id == product.product_id)
    return Number(res[0].amount_before)
  }

  const handleSubmit = (e) => {
    let finalProductPayload = [];

    // Loop for transform input product (number) to array of object -- ignore product with 0 amount
    numberProducts.forEach((item, index) => {
      let productBefore = berangkatProductList.filter(ele => ele.product_id == item.product_id)
        finalProductPayload.push({
          product_id: item.product_id,
          amount_before: 0,
          amount_after: e[item.product_id] == undefined ? Number(productBefore[0].amount_before) : e[item.product_id] ,
        });
    });
    // Loop for transform input product (checkbox) to array of object -- fixed amount 1
    checkBoxProducts.forEach((product) => {
      finalProductPayload.push({
        product_id: product.product_id,
        amount_before: 0,
        amount_after: 1,
      });
    });

    let payload = {
      event_id: eventReducer.event_id,
      product: finalProductPayload,
    };
    updateEventPulang(payload).then((res) => {
      sendEmail(res.limitStock)
      navigate("/staff-kehilangan");
    });
  };

  const sendEmail = (data) => {
    let user = JSON.parse(localStorage.getItem('userInfo'));
      for(let i=0;i < data.length;i++){
        let bodyEmail = {
          to_name: "Poonya Admin",
          from_name: user.fullname,
          product_name: data[i].product_name,
          product_type: data[i].product_type,
          stock_limit: data[i].limit_stock,
          curr_stock: data[i].quantity,
          receiver: EMAIL_CONFIG.RECEIVER,
          receiver_cc: EMAIL_CONFIG.RECEIVER_CC
        };
        emailjs.send(EMAIL_CONFIG.SERVICE_ID,EMAIL_CONFIG.TEMPLATE_STOCK_LIMIT,bodyEmail, {
          publicKey: EMAIL_CONFIG.PUBLIC_KEY,
        });
      }
     }
  

  const getEventDetailAndStaff = async () => {
    await getAllStaff().then(res => {
      getEventStaffByEvent(eventReducer.event_id).then(data => {
        const resultStaff = data.eventStaff.map(v => 
          ({ 
            ...v,
            ...res.find(sp => sp.staff_id === v.staff_id) 
          })
        );
        setEventStaffDetail(resultStaff);
      });
    });

    await getEventById(eventReducer.event_id).then(response => {
      setEventDetail(response);
    });
  }

  return (
    <div className="container">
      <div className="header">
       <div className="header-info">
              <h2>{eventStaffDetail.length != 0 ? eventStaffDetail[0].fullname : ''}</h2>
              <p>{eventDetail != undefined ? eventDetail.customer_id : ''} - {eventDetail != undefined ? formatDate(eventDetail.event_date) : ''}</p>
        </div>
        <Button type="primary" size="large">
          Form Pulang
        </Button>
      </div>
      <Steps current={2} className="steps-bar">
        <Step title="Input Event" />
        <Step title="Form Berangkat" />
        <Step title="Form Pulang" />
        <Step title="Form Kehilangan" />
      </Steps>
      <div className="form-container">
        <h2 style={{ textAlign: "left" }}>Form Pulang</h2>
        <Form onFinish={handleSubmit}>
          <Form.Item 
                name="checkbox_product"
                labelCol={{ span: 24 }}
                // valuePropName='checked'
                // getValueProps={(value) => ({ checked: !value })}
                // getValueFromEvent={(e) => !e.target.checked}
            >
            <CheckboxGroup style={{ width: "100%" }}>
                {checkBoxProducts.map((product) => (
                  <Checkbox
                    disabled={true}
                    key={product.product_id}
                    value={product.product_id}
                    defaultChecked={true}
                  >
                    {product.product_name}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </Form.Item>
          {/* <Form.Item>
            <CheckboxGroup style={{ width: "100%" }}>
              <div className="checkbox-grid">
                {checkBoxProducts.map((product) => (
                  <Checkbox
                    key={product.product_id}
                    value={product.product_id}
                    defaultChecked={true}
                  >
                    {product.product_name}
                  </Checkbox>
                ))}
              </div>
            </CheckboxGroup>
          </Form.Item> */}
          {numberProducts.map((item) => (
           <div key={item.product_id}>
           <p>Enter amount for {item.product_name}:</p>
           <Form.Item
             name={item.product_id}
           >
             <InputNumber 
             defaultValue={defaultValue(item)}
            //  value={defaultValue(item)} 
             style={{ width: "100%" }} controls={false} />
           </Form.Item>
         </div>
         
          ))}
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%", marginTop: "10px" }}
          >
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default StaffPulang;
