import React, { Component } from "react";

import StaffEvent from "../pages/StaffEvent";

export default class StaffEventView extends Component {
  render() {
    return (
      <div>
        {/* <Header {...this.props}></Header> */}
        <StaffEvent data={StaffEvent}></StaffEvent>
      </div>
    );
  }
}
