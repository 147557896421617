import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import StoreProvider from "./redux/store";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import store from './redux/store';
import { store, persistor } from './redux/store';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <StoreProvider>
  // <Provider store={store}>
        // <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
            <App />
    </React.StrictMode>

        // {/* </PersistGate> */}
    // </Provider>
  // {/* </StoreProvider> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
