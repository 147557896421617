import React, { Component } from "react";

import StaffPulang from "../pages/StaffPulang";

export default class StaffPulangView extends Component {
  render() {
    return (
      <div>
        {/* <Header {...this.props}></Header> */}
        <StaffPulang data={StaffPulang}></StaffPulang>
      </div>
    );
  }
}
