import React from "react";
import { Button, Checkbox, DatePicker, Form, Input, Steps } from "antd";
import "./index.css";
import { getAllStaff, getStaffById } from "../../api/staff";
import { useState, useEffect } from "react";
import { createEvent } from "../../api/event";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/datetime";
import { EMAIL_CONFIG } from "../../const/email";
import emailjs from '@emailjs/browser';

const { Step } = Steps;

const StaffInput = () => {
  const navigate = useNavigate();
  const dateFormat = 'YYYY-MM-DD';

  let [staffList, setStaffList] = useState([])

  useEffect(() => {
    getAllStaff().then(res => {
      setStaffList(res);
    })
  }, []);
  
  const sendEmail = async (data) => {
    let user = JSON.parse(localStorage.getItem('userInfo'));
    await getStaffById(data.staff[0]).then(res => {
      let bodyEmail = {
        to_name: "Poonya Admin",
        from_name: user.fullname,
        event_name: data.event_name,
        event_pic: res.fullname,
        event_date: formatDate(data.event_date),
        subject: "Create Event",
        receiver: EMAIL_CONFIG.RECEIVER,
        receiver_cc: EMAIL_CONFIG.RECEIVER_CC
      };
      emailjs.send(EMAIL_CONFIG.SERVICE_ID,EMAIL_CONFIG.TEMPLATE_EVENT,bodyEmail, {
        publicKey: EMAIL_CONFIG.PUBLIC_KEY,
      }).then(() => navigate('/staff-listevent'));
    })
     }
  
   
  const handleSubmit = (e) => {
    for(const[key, value] of Object.entries(e)){  if(typeof value.toDate=='function') e[key] = value.format(dateFormat);}
    let payload = {
      event_name: e.event_name,
      event_date: e.event_date,
      customer_id: e.customer_name,
      staff: e.staff // Array staff ID
  }
    createEvent(payload).then(res => {
      sendEmail(payload)
      // navigate(0)
    })
  }

  const back = () => {
    navigate('/staff-start');
  };

  return (
    <div className="container">
      <div className="header">
        <Button type="primary" size="large">
          Input Event
        </Button>
      </div>
      <Steps current={0} className="steps-bar">
        <Step title="Input Event" />
        <Step title="Form Berangkat" />
        <Step title="Form Pulang" />
        <Step title="Form Kehilangan" />
      </Steps>
      <div className="form-container">
        <h2 style={{ textAlign: "left" }}>Input Form</h2>
        <Form name="eventForm" layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item label="Nama Staff" name="staff" rules={[{ required: true }]}>
            <Checkbox.Group>
                {staffList.map((item) => {
                  return(
                  item.role === "staff" ? 
                  <Checkbox value={item.staff_id} key={item.staff_id}> {item.fullname} </Checkbox>
                : null) })
                }
            </Checkbox.Group>
          </Form.Item>

          <Form.Item label="Nama Event" name="event_name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Nama Customer" name="customer_name" rules={[{ required: true }]}>
            <Input />
          </Form.Item> 

          <Form.Item label="Tanggal Acara" name="event_date" rules={[{ required: true }]}>
            <DatePicker style={{ width: "100%" }} format={dateFormat}/>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Submit
            </Button>
            <Button onClick={back} type="danger" htmlType="submit" style={{ width: "100%", backgroundColor:'red' }}>
              Back
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default StaffInput;
