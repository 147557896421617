import React, { Component } from "react";

import StaffFinish from "../pages/StaffFinish";

export default class StaffFinishView extends Component {
  render() {
    return (
      <div>
        {/* <Header {...this.props}></Header> */}
        <StaffFinish data={StaffFinish}></StaffFinish>
      </div>
    );
  }
}
