import React, { useState } from "react";
import "./index.css";
import Header from "../../components/Header/header";
// import StockTable from "../../components/StockTable/stocktable";
import ProductTable from "../../components/ProductTable/producttable";
import { useNavigate } from 'react-router-dom';

import Sidebar from "../../components/Sidebar/sidebar";
import { Modal, Input, Form, Select } from "antd";
import { createProduct } from "../../api/product";
import { formatDate } from "../../utils/datetime";


const { Option } = Select;

const Inventory = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    quantity: "",
    purchaseDate: "",
    productType: "", // Added for dropdown
    inputType: "",
    price:0
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    // Handle form submission
    await createProduct(formData).catch(err => console.log(err));
    setIsModalVisible(false);
    navigate("/inventory");
    // navigate(0)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChangeProductType = (value) => {
    setFormData({
      ...formData,
      productType : value,
    });
  };

  const handleSelectChangeInputType = (value) => {
    setFormData({
      ...formData,
      inputType : value,
    });
  };

  return (
    <div className="app-container">
      <Sidebar />
      <div className="content">
        <Header />
        <div className="header-row">
          <h1>Inventory</h1>
          <button
            className="tambah-stock-button"
            onClick={showModal}
          >
            Tambah Produk Baru
          </button>
        </div>
       
        <br />
        <ProductTable />
      </div>

      {/* Modal for Tambah Produk Baru */}
      <Modal
        title="Tambah Produk Baru"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form>
          <Form.Item label="Nama Produk" style={{paddingTop:'20px'}}>
            <Input
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item label="Product Type">
            <Select
              value={formData.productType}
              onChange={handleSelectChangeProductType}
              placeholder="Select type"
            >
              <Option value="poonya-moments">Poonya Moments</Option>
              <Option value="poonya-box">Poonya Box</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Input Type">
            <Select
              value={formData.inputType}
              onChange={handleSelectChangeInputType}
              placeholder="Select type"
            >
              {/* <Option value="dropdown">Drop Down</Option> */}
              <Option value="checkbox">CheckBox</Option>
              <Option value="text">Text</Option>
              <Option value="number">Number</Option>
              <Option value="radio">Radio</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Quantity">
            <Input
              name="quantity"
              type="number"
              value={formData.quantity}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item label="Price">
            <Input
              name="price"
              type="number"
              value={formData.price}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item label="Purchase Date">
            <Input
              name="purchaseDate"
              type="date"
              value={formData.purchaseDate}
              onChange={handleChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Inventory;
