import axios from 'axios';
import { BACKEND_API_URL } from '../index';
import { Product } from '../../model/product';
import React, { useState } from "react";

export const getAllEvents = async () => {
  try {
   
    // Await the axios call and get the response
    const res = await axios.get(`${BACKEND_API_URL}/events`);
    // Assuming res.data contains the response we need
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getEventByStatus = async (status) => {
    try {
     
      // Await the axios call and get the response
      const res = await axios.get(`${BACKEND_API_URL}/status/${status}`);
      // Assuming res.data contains the response we need
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  export const getEventById = async (id) => {
    try {
      // Await the axios call and get the response
      const res = await axios.get(`${BACKEND_API_URL}/event/id/${id}`);
      // Assuming res.data contains the response we need
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  export const getEventProductByEvent = async (event_id) => {
    try {
     
      // Await the axios call and get the response
      const res = await axios.get(`${BACKEND_API_URL}/event/event-product/${event_id}`);
      // Assuming res.data contains the response we need
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  export const getEventStaffByEvent = async (event_id) => {
    try {
     
      // Await the axios call and get the response
      const res = await axios.get(`${BACKEND_API_URL}/event/event-staff/${event_id}`);
      // Assuming res.data contains the response we need
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };


  export const getEventLostOption = async (event_id) => {
    try {
     
      // Await the axios call and get the response
      const res = await axios.get(`${BACKEND_API_URL}/event/lost-option/${event_id}`);
      // Assuming res.data contains the response we need
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

export const createEvent = async (data) => {
  let user = JSON.parse(localStorage.getItem('userInfo'));
  try {
    
    let body = {
        "event_name": data.event_name,
        "event_date": data.event_date,
        "customer_id": data.customer_id,
        "username": user.username,
        "staff": data.staff // Array staff ID
    }
    // Await the axios call and get the response
    const res = await axios.post(`${BACKEND_API_URL}/event`, body);
    // Assuming res.data contains the response we need
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateEventBerangkat = async (data) => {
    let user = JSON.parse(localStorage.getItem('userInfo'));
    try {
      
      let body = {
        "username": user.username,
        "event_id": data.event_id,
        "product": data.product // Array Product Object {product_id, amount_before, amount_after}
    }
      // Await the axios call and get the response
      const res = await axios.post(`${BACKEND_API_URL}/event/berangkat`, body);
      // Assuming res.data contains the response we need
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
  
  export const updateEventPulang = async (data) => {
    let user = JSON.parse(localStorage.getItem('userInfo'));
    try {
      
        let body = {
            "username": user.username,
            "event_id": data.event_id,
            "product": data.product // Array Product Object {product_id, amount_before, amount_after}
        }
      // Await the axios call and get the response
      const res = await axios.post(`${BACKEND_API_URL}/event/pulang`, body);
      // Assuming res.data contains the response we need
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  export const updateEventKehilangan = async (data) => {
    let user = JSON.parse(localStorage.getItem('userInfo'));
    try {
      
      let body = {
        "username": user.username,
        "event_id": data.event_id,
        "product_id": data.product_id,
        "lost_amount": data.lost_amount,
        "lost_reason": data.lost_reason
    }
      // Await the axios call and get the response
      const res = await axios.post(`${BACKEND_API_URL}/event/kehilangan`, body);
      // Assuming res.data contains the response we need
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  export const updateEventFinished = async (data) => {
    let user = JSON.parse(localStorage.getItem('userInfo'));
    try {
      
        let body = {
            "username": user.username,
            "event_id": data
        }
      // Await the axios call and get the response
      const res = await axios.post(`${BACKEND_API_URL}/event/finished`, body);
      // Assuming res.data contains the response we need
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  export const updateEvenCancelled = async (data) => {
    let user = JSON.parse(localStorage.getItem('userInfo'));
    try {
      
        let body = {
            "username": user.username,
            "event_id": data.event_id
        }
      // Await the axios call and get the response
      const res = await axios.post(`${BACKEND_API_URL}/event/cancelled`, body);
      // Assuming res.data contains the response we need
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
