
import styled from "styled-components";
import React, { useState, useEffect } from "react";

const HeaderContainer = styled.div`
  padding: 20px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('userInfo')));
  // useEffect(() => {
  //   // Axios.get(
  //   //   "https://be-logistic.studiopoonya.net/login"
  //   //   // "http://localhost:5000/setting/price"
    
  //   //   
  //   // .then((res) => {
  //   //   // setCurrentPrice(res.data.value);
  //   //   console.log(res.data)
  //   //   // console.log(currentPrice);
  //   // })
  //   // .catch((error) => {
  //   //   console.log("error");
  //   // });

  // }, [user]);
  
  return (
    <HeaderContainer>
      <h1>Welcome Back {user.fullname} 👋</h1>
    </HeaderContainer>
  );
}

export default Header;
