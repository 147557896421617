// import { createStore } from "redux";
// import { Provider } from "react-redux";
// import rootReducer from "./reducers";

// const store = createStore(rootReducer);

// // const StoreProvider = ({ children }) => {
// //   return <Provider store={store}>{children}</Provider>;
// // };

// export default store;


import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor };
