import Paper from "./logopaper.png";
import CoverFrame2R from "./logoframe2r.png";
import CoverFrame4R from "./logoframe4r.png";
import KartuNama from "./logocard.png";
import Magnet from "./logomagnet.png";
import Spidol from "./logopen.png";
import Lakban from "./logotape.png";
import PropertyBando from "./logoprops.png";
import PropertyKacamata from "./logoprops.png";
import Keychain from "./logokey.png";
import DefaultImage from './default.png';

export default {
    Paper, 
    CoverFrame2R, 
    CoverFrame4R, 
    KartuNama, 
    Magnet, 
    Spidol,
    Lakban,
    PropertyBando, 
    PropertyKacamata, 
    Keychain,
    DefaultImage
}