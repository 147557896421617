import React from "react";
import "./index.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getEventStaffByEvent } from "../../api/event";
import { getAllProducts } from "../../api/product";
import { getAllStaff } from "../../api/staff";
import { useSelector, useDispatch } from "react-redux";
import { updateEventBerangkat, getEventById } from "../../api/event";



import { formatDate } from "../../utils/datetime";

const StaffFinish = () => {
  let [eventDetail, setEventDetail] = useState();
  let [eventStaffDetail, setEventStaffDetail] = useState([]);
  let user = JSON.parse(localStorage.getItem('userInfo'));
  const eventReducer = useSelector(state => state.eventReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.clear()
    dispatch({ type: 'LOGOUT' });
    navigate('/');
  };

  useEffect(() => {
    let event_id = eventReducer.event_id;
    getEventDetailAndStaff();
  },[])

  const getEventDetailAndStaff = async () => {
    await getAllStaff().then(res => {
      getEventStaffByEvent(eventReducer.event_id).then(data => {
        const resultStaff = data.eventStaff.map(v => 
          ({ 
            ...v,
            ...res.find(sp => sp.staff_id === v.staff_id) 
          })
        );
        setEventStaffDetail(resultStaff);
      });
    });

    await getEventById(eventReducer.event_id).then(response => {
      setEventDetail(response);
    });
  }

  return (

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Full viewport height
      textAlign: 'center'
    }}>
      <h1 className="title">
      Thankk Youu  <span role="img" aria-label="wave">
          👋
        </span>
      <br/>{user.fullname}
       
      </h1>
      <button className="event-button" onClick={logout} >Logout</button>
      </div>
  );
};

export default StaffFinish;
